if (document.querySelector(".caseStudy__swiper")) {
    var swiper = new Swiper(".caseStudy__swiper", {

        freeMode: true,
        loading: lazy,
        navigation: {
            nextEl: ".caseStudy .swiper-button-next",
            prevEl: ".caseStudy .swiper-button-prev",
        },


        keyboard: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",
            paginationBulletMessage: 'Przejdź na slajd {{index}}',

        },
    });
}
